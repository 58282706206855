






















































































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { vxm } from '@/store'
import { appendSitePrefix } from '@/utils/routeUtils'

@Component
export default class ReconcileCustomerBalances extends Vue {
  private isLoading = false
  private includeNoDiff = false
  private useCache = false
  private maxInvoiceDate = ''
  private data = null
  private showDetailsDialog = false
  private showDetailsItem = null

  private get hasData() {
    return this.data?.customers
  }

  private showDetails(customer) {
    this.showDetailsDialog = true
    this.showDetailsItem = customer
  }

  private created() {
    if (this.$route.query?.maxInvoiceDate) {
      this.maxInvoiceDate = this.$route.query.maxInvoiceDate as string
    }
    if (this.$route.query?.includeNoDiff) {
      this.includeNoDiff = true
    }
    if (this.$route.query?.useCache) {
      this.useCache = true
    }
  }

  private clickSubmit() {
    this.$router.push({
      name: '',
      query: {
        maxInvoiceDate: this.maxInvoiceDate,
        includeNoDiff: this.includeNoDiff ? '1' : '',
        useCache: this.useCache ? '1' : '',
      },
    })
    this.load()
  }

  private load() {
    this.isLoading = true
    const query =
      '?maxInvoiceDate=' +
      this.maxInvoiceDate +
      '&includeNoDiff=' +
      (this.includeNoDiff ? '1' : '') +
      '&useCache=' +
      (this.useCache ? '1' : '')
    this.$axios
      .get('/v4/site/integrations/accounting/reconcile-customer-balances' + query)
      .then((response) => {
        this.data = response.data.data
        this.isLoading = false
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed fetching data')
        this.isLoading = false
      })
  }

  private getNowDate(): string {
    const now = new Date()
    const y = now.getFullYear()
    const mNum = now.getMonth() + 1
    const dNum = now.getDate() + 1
    const m = mNum < 10 ? '0' + mNum : '' + mNum
    const d = dNum < 10 ? '0' + dNum : '' + dNum
    return y + '-' + m + '-' + d
  }

  private getInvoiceUrl(invoiceNumber): string {
    const url =
      '/U/ledger/main?to=' +
      this.getNowDate() +
      '&mode=easy&invoice=' +
      invoiceNumber +
      '&submitted=1&group_by=&is_closed=&show_details=1'
    return appendSitePrefix(url)
  }

  private formatDiff(a, b, usePrefix: boolean): string {
    const an = parseFloat(a)
    const bn = parseFloat(b)
    const diff = an - bn
    if (Math.abs(diff) < 0.001) {
      return ''
    }
    const prefix = usePrefix ? 'Diff: ' : ''
    return prefix + Math.round(diff * 100) / 100
  }
}
